/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql, useStaticQuery } from "gatsby"

const TranslationGridPage = () => {
  const { content } = useStaticQuery(
    graphql`
        query {
          content: allLocalesJson {
            locales: nodes {
              translations {
                key
                value
              }
              title
            }
          }
        }
      `
  )

  const translations: any = {}
  const phrases: any = {}
  for (let locale of content.locales) {
    if (locale.title == 'en')
      continue
    for (let translation of locale.translations) {
      translations[locale.title] || (translations[locale.title] = {})
      translations[locale.title][translation.key] || (translations[locale.title][translation.key] = {})
      translations[locale.title][translation.key] = translation.value
      phrases[translation.key] = true
    }
  }

  const tableStyle = {
    borderCollapse: "collapse",
    fontFamily: "monospace",

  }

  const headerStyle: SystemStyleObject = {
    position: "sticky",
    top: 0,
    background: "#000",
    color: "white",
  }

  const leftColStyle: SystemStyleObject = {
    textAlign: "left",
    fontSize: "10px",
    border: "1px solid #000"
  }

  return (
    <table sx={tableStyle} >
      <thead>
        <tr>
          <th sx={headerStyle}>
            Source Phrase
          </th>
          {Object.keys(translations).sort().map(lang => (
            <th sx={headerStyle}>{lang}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.keys(phrases).map(phrase => (
          <tr>
            <th sx={leftColStyle}>
              {phrase}
            </th>
            {Object.keys(translations).sort().map(lang => (
              <td sx={{
                background: translations[lang][phrase] ? '#a1d99b' : '#e34a33',
                border: "1px solid #000",
                width: "2em",
                height: "1.5em",
              }} title={translations[lang][phrase]}>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table >
  )
}

export default TranslationGridPage